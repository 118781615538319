<template>
  <TTView>
    <TTExample
      file="components/TTPrompt/examples/TTPrompt.example"
    />
  </TTView>
</template>

<script>

export default {
  name: 'Prompt',
  data() {
    return {};
  },
};
</script>
